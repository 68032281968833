<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card min-height="540" :loading="loading">
    <v-card-title class="d-flex py-1">
      <div class="text-h6 font-weight-regular text--primary">Sales Total</div>
    </v-card-title>
    <v-divider />
    <div class="d-flex pa-1">
      <div>
        <v-select
          :items="workers"
          outlined
          v-model="employeeUuid"
          clearable
          item-text="fullname"
          item-value="uuid"
          @change="getSummary1"
          dense
          label="Filter by Employee"
        ></v-select>
      </div>
      <div class="mx-1">
        <v-select
          :items="patients"
          v-model="patientUuid"
          clearable
          item-text="fullname"
          item-value="uuid"
          @change="getSummary1"
          outlined
          dense
          label="Filter by Patient"
        ></v-select>
      </div>

      <template v-if="dateInterval !== 'this-year'">
        <div>
          <v-btn color="error" class="mx-2" @click="clearFilter">Clear</v-btn>
        </div>
      </template>
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              :loading="loading"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-tune</v-icon>
              Filter
            </v-btn>
          </template>

          <v-list width="140" nav dense>
            <v-list-item
              v-for="interval in intervals"
              :key="interval.value"
              link
              @click="setIntervar(interval.value)"
            >
              <v-list-item-title>{{ interval.label }}</v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item link @click="dateInterval = 'select'">
              <v-list-item-title>Select</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    {{/* Interval indicator */}}
    <v-layout class="pa-4" column>
      <div class="mr-2 text-capitalize">
        {{ dateInterval.replace("-", " ") }}
      </div>
      <v-layout column class="text-body-1 text--secondary">
        <div>
          From <span class="text-caption">{{ prettyDate(query.date1) }}</span>
        </div>
        <div>
          To <span class="text-caption">{{ prettyDate(query.date2) }}</span>
        </div>
      </v-layout>
    </v-layout>

    {{/* interval selector */}}
    <v-slide-y-transition>
      <v-layout class="ma-4" v-if="dateInterval == 'select'" column>
        <div class="text-body-1 mb-2">Select a date interval</div>
        <v-row no-gutters>
          <v-col>
            <ma-date-picker v-model="dateFrom" label="From" past />
          </v-col>
          <v-col class="ml-2">
            <ma-date-picker v-model="dateTo" label="To" past />
          </v-col>
        </v-row>
      </v-layout>
    </v-slide-y-transition>

    <v-card-text class="d-flex flex-column">
      <div class="d-flex align-center">
        <div class="text-secondary text-body-1 mb-3">
          Total
          <b class="font-weight-bold text--primary">{{ total | currency }}</b>
        </div>
      </div>
      <div class="d-flex justify-center">
        <chart-base
          ref="chart"
          :width="360"
          :height="250"
          chart-type="pie"
          :chartData="chartData"
          :chartOptions="chartOptions"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import ChartBase from "./ChartBase.vue";

import moment from "moment";
import { getAPI } from "@/api/axios-base";
import { AxiosResponse } from "axios";
import groupBy from "lodash/groupBy";
import forEach from "lodash/forEach";
import { mapActions, mapState } from "vuex";
// import { isDevelop } from "@/enviorment";

export default Vue.extend({
  name: "sales-total",
  components: {
    ChartBase,
    MaDatePicker,
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",
      loading: false,
      type: "Weeks",

      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            borderColor: ["#2196f3"],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          // title: {
          //   display: true,
          //   text: "Summary",
          // },
        },
      },
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      dateInterval: "this-year",
      dataGraphic: [],
      patientUuid: null,
      employeeUuid: null,
      grouping: "YEAR",
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    intervalToday() {
      const from = moment();
      from.hour(0);
      from.minute(0);
      from.second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").startOf("day");
      const to = moment().subtract(1, "days").endOf("day");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().day(0).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").startOf("week");
      const to = moment().subtract(1, "week").endOf("week");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().date(1).hour(0).minute(0).second(0);
      const to = moment().hour(23).minute(59).second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment().subtract(1, "month").startOf("month");

      const to = moment().subtract(1, "month").endOf("month");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().month(0).date(1).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment()
        .subtract(1, "year")
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .month(0)
        .date(1)
        .subtract(1, "day")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },

    total() {
      let count = 0;
      this.dataGraphic.forEach((dg) => {
        count = count + Number(dg.total);
      });
      return count;
    },
  },
  watch: {
    dateFrom(val) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getSummary1();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getSummary1();
      }
    },
  },
  async mounted() {
    await this.actGetEmployees();
    await this.actListPatientsFilter();
    this.getData();
    this.getSummary1();
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),

    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    setIntervar(interval) {
      this.dateInterval = interval;
      this.getSummary1();
    },
    // Agrupar los datos por día de la semana
    datasetsByDay(data) {
      return groupBy(data, (item) => {
        return item.s_status;
      });
    },
    clearFilter() {
      this.setIntervar("this-year");
    },
    getData() {
      let interval;

      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "select":
          interval = {
            date1: moment(this.dateFrom).toISOString(),
            date2: moment(this.dateTo).toISOString(),
          };
          break;
      }

      this.loading = true;
      this.query = interval;
      getAPI.post("/payment/filterDays", interval).then((response) => {
        const responseData = response.data;

        this.loading = false;
      });
    },

    getSummary1() {
      this.loading = true;
      let interval;
      let body = {
        employeeUuid: this.employeeUuid,
        patientUuid: this.patientUuid,
      };
      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "select":
          interval = {
            date1: moment(this.dateFrom).toISOString(),
            date2: moment(this.dateTo).toISOString(),
          };
          break;
      }

      body = { ...body, dateRange: interval, grouping: this.grouping };
      body = this.cleanNull(body);

      this.query = interval;
      getAPI.post("/sales/sumary1", body).then((res) => {
        this.loading = false;
        this.dataGraphic = res.data;
        this.createChart(res.data);
      });
    },
    createChart(data) {
      const dataValues = data.filter((item) => item.s_status !== "TOTAL");
      const datasetsByDay = this.datasetsByDay(dataValues);
      const datasetByType = groupBy(dataValues, (item) => item.s_status);
      const dat = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      // isDevelop && console.log(datasetsByDay, datasetByType);
      // Reset chart
      this.$refs.chart.chart.data.labels = [];
      this.$refs.chart.chart.data.datasets = [];
      this.$refs.chart.chart.update();

      // Take labels
      for (let [type, value] of Object.entries(datasetByType)) {
        dat[0].data.push(value[0].total);
        dat[0].backgroundColor.push(this.getColor(type));
      }

      /*   this.$refs.chart.chart.data.datasets.data.push(
          datasetByType[type][0].total
        ); */

      this.$refs.chart.chart.data.labels = Object.keys(datasetByType);
      this.$refs.chart.chart.data.datasets = dat;
      /*    this.dataGraphic.forEach((element) => {
        console.log(element);
      });

      console.log(this.$refs.chart.chart.data, "datos"); */

      this.$refs.chart.chart.update();
    },

    getColor(value) {
      switch (value) {
        case "CONFIRMED":
          return "#00E676";

        case "UNCONFIRMED":
          return "#FFAB91";

        case "REFOUNDED":
          return "#607D8B";

        default:
          return "#E53935";
      }
    },
    colorGenerator(value) {
      return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
});
</script>
