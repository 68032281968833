var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"min-height":"540","loading":_vm.loading}},[_c('v-card-title',{staticClass:"d-flex align-center py-1"},[_c('div',{staticClass:"text-h6 font-weight-regular text--primary"},[_vm._v(" Leads Converted ")])]),_c('v-divider'),(!_vm.loading)?[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[[_c('span',{staticClass:"d-flex justify-center font-weight-bold"},[_vm._v("Converted Leads")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.converteds,"items-per-page":5},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient ? item.patient.fullname : "")+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient ? _vm.getDate(item.patient.createAt) : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.gotopatient(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View "+_vm._s(_vm.getpatientN)+" Details")])])]}}],null,true)})]],2),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[(_vm.emptyData && _vm.loading)?[_c('div',[_vm._v("cargando")])]:(_vm.emptyData && !_vm.loading)?[_c('div',[_vm._v("no data")])]:[_c('chart-base',{ref:"chartleads",attrs:{"height":250,"width":360,"chart-type":"pie","chartData":_vm.chartData,"chartOptions":_vm.chartOptions}})]],2)],1)],1)]:[_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }