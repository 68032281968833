<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column fill-height position-relative px-4">
    <div class="text-h5 px-3 mb-3">Reports</div>
    <v-tabs v-model="tab">
      <v-tab href="#tab-1">Sales</v-tab>
      <v-tab href="#tab-2">Sales-Now</v-tab>
      <v-tab href="#tab-3">User Sales</v-tab>

      <v-tab href="#tab-4">Leads</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1" class="pa-2">
        <sales />
        <v-divider class="my-4"></v-divider>
        <financial />
      </v-tab-item>
      <v-tab-item value="tab-2" class="pa-2">
        <chart-sales-now />
      </v-tab-item>
      <v-tab-item value="tab-3" class="pa-2"><user-sales-summary /></v-tab-item>
      <v-tab-item value="tab-4" class="pa-2">
        <v-row column>
          <!--   <v-col cols="12" sm="12">
            <lead-summary />
          </v-col> -->
          <v-col cols="12" sm="12">
            <chart-leads />
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Sales from "@/views/Configurations/Sales.vue";
import Financial from "@/views/Configurations/Financial.vue";
import ChartLeads from "@/components/Finantial/ChartLeads.vue";

import ChartSalesNow from "@/components/Finantial/ChartSalesNow.vue";
import userSalesSummary from "@/components/Finantial/userSalesSummary.vue";
import leadSummary from "@/components/Finantial/leadSummary.vue";

export default {
  components: {
    Sales,
    Financial,
    ChartSalesNow,
    ChartLeads,
    userSalesSummary,
    leadSummary,
  },
  name: "reports",
  data() {
    return {
      tab: "tab-1",
    };
  },
  watch: {
    tab: {
      handler() {
        localStorage.setItem("currentTab", this.tab);
      },
    },
  },
  mounted() {
    this.tab = localStorage.getItem("currentTab")
      ? localStorage.getItem("currentTab")
      : "tab-1";
  },
};
</script>
<style lang="scss" scoped></style>
