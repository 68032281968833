<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex justify-center">
    <chart-base
      :height="700"
      :width="800"
      ref="chartsales"
      chart-type="bar"
      :chartData="chartData"
      :chartOptions="chartOptions"
    />
  </div>
</template>
<script>
import groupBy from "lodash/groupBy";
import ChartBase from "./ChartBase.vue";
import { utilMixin } from "@/utils/mixins";
import { mapGetters } from "vuex";
export default {
  mixins: [utilMixin],
  components: { ChartBase },
  props: {
    dataChart: {
      type: Array,
      default: () => [],
    },
  },
  name: "chart-lead-summary",
  data() {
    return {
      loading: false,
      totallead: 0,
      totalconvertedpercent: 0,
      totalnotconvertedpercent: 0,

      converteds: [],

      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            borderColor: ["#2196f3"],
          },
        ],
      },

      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          // title: {
          //   display: true,
          //   text: "Summary",
          // },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
  },

  methods: {
    createChart() {
      const dataValues = this.dataChart.filter(
        (item) => item.name !== "Total" && item.total != 0
      );
      const datasetByType = groupBy(dataValues, (item) => item.name);
      const dat = [
        {
          label: "Total Leads",
          data: [],
          borderColor: "#4CAF50",
          backgroundColor: "#4CAF50",
        },
        {
          label: "Converted to Patients",
          data: [],
          borderColor: "#2196F3",
          backgroundColor: "#2196F3",
        },
      ];

      // Take labels
      for (let [type, value] of Object.entries(datasetByType)) {
        dat[0].data.push(value[0].leadscount);
        dat[1].data.push(value[0].patientconvertedcount);
      }

      /*   this.$refs.chart.chart.data.datasets.data.push(
          datasetByType[type][0].total
        ); */

      let sum1 = 0;
      dat[0].data.forEach((d) => {
        sum1 = sum1 + Number(d);
      });
      let sum2 = 0;
      dat[1].data.forEach((d) => {
        sum2 = sum2 + Number(d);
      });

      dat[0].label = dat[0].label + `(${sum1})`;
      dat[1].label = dat[1].label + `(${sum2})`;

      this.$refs.chartsales.chart.datasets = dat;
      this.$refs.chartsales.chart.labels = Object.keys(datasetByType);

      /*    this.dataGraphic.forEach((element) => {
        console.log(element);
      });

      

      console.log(this.$refs.chart.chart.data, "datos"); */

      /* this.$refs.chart.chart.update(); */
    },

    colorGenerator() {
      return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
    },
  },
  mounted() {
    this.$refs.chartsales.chart = this.chartData;
    this.createChart();
  },
};
</script>
<style lang="scss" scoped>
.pie {
  border: 1px solid red;
  max-height: 400px;
}
.loading {
  min-height: 540px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
</style>
