import { render, staticRenderFns } from "./ChartuserSales.vue?vue&type=template&id=68342478&scoped=true&"
import script from "./ChartuserSales.vue?vue&type=script&lang=js&"
export * from "./ChartuserSales.vue?vue&type=script&lang=js&"
import style0 from "./ChartuserSales.vue?vue&type=style&index=0&id=68342478&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68342478",
  null
  
)

export default component.exports