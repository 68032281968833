




























































































import Vue from "vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import ChartBase from "./ChartBase.vue";

import moment from "moment";
import { getAPI } from "@/api/axios-base";
import { AxiosResponse } from "axios";
import groupBy from "lodash/groupBy";
import forEach from "lodash/forEach";
// import { isDevelop } from "@/enviorment";

export default Vue.extend({
  name: "recent-sales",
  components: {
    ChartBase,
    MaDatePicker,
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",
      loading: false,
      total: 0,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            borderColor: ["#2196f3"],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          // title: {
          //   display: true,
          //   text: "Summary",
          // },
        },
      },
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      dateInterval: "today",
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  computed: {
    intervalToday() {
      const from = moment();
      from.hour(0);
      from.minute(0);
      from.second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").hour(0).minute(0).second(0);
      const to = moment().subtract(1, "days").hour(23).minute(59).second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().day(0).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment()
        .subtract(1, "week")
        .day(0)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .subtract(1, "week")
        .add(6, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().date(1).hour(0).minute(0).second(0);
      const to = moment().hour(23).minute(59).second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return (this as any).intervarThisMonth;
      }

      const from = moment()
        .subtract(1, "month")
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .date(1)
        .subtract(1, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().month(0).date(1).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment()
        .subtract(1, "year")
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .month(0)
        .date(1)
        .subtract(1, "day")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
  },
  watch: {
    dateFrom(val: string) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getData();
      }
    },
    dateTo(val: string) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getData();
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    setIntervar(interval: string) {
      this.dateInterval = interval;
      this.getData();
    },
    // Agrupar los datos por día de la semana
    datasetsByDay(data: any[]) {
      return groupBy(data, (item) => {
        return moment(item.pay_day).format("ddd D");
      });
    },
    clearFilter() {
      this.setIntervar("today");
    },
    getData() {
      let interval;

      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "select":
          interval = {
            date1: moment(this.dateFrom).toISOString(),
            date2: moment(this.dateTo).toISOString(),
          };
          break;
      }

      this.loading = true;
      this.query = interval;
      getAPI
        .post("/payment/filterDays", interval)
        .then((response: AxiosResponse) => {
          const responseData = response.data;

          this.loading = false;
          this.total = parseFloat(responseData.slice(-1)[0].sum);
          this.createChart(responseData);
        });
    },
    createChart(data: { payment_type: string; sum: string }[]) {
      const dataValues = data.filter((item) => item.payment_type !== "TOTAL");
      const datasetsByDay = this.datasetsByDay(dataValues);
      const datasetByType = groupBy(dataValues, (item) => item.payment_type);

      // isDevelop && console.log(datasetsByDay, datasetByType);
      // Reset chart
      this.$refs.chart.chart.data.labels = [];
      this.$refs.chart.chart.data.datasets = [];
      this.$refs.chart.chart.update();

      // Take labels
      forEach(datasetsByDay, (datasets, dayOfWeek) => {
        this.$refs.chart.chart.data.labels.push(dayOfWeek);
      });

      forEach(datasetByType, (data, type) => {
        // Inicializar con ceros
        let values = (() => {
          let items: any[] = [];
          this.$refs.chart.chart.data.labels.forEach(() => items.push(0));

          return items;
        })();

        // Cada posicion de los valores debe coincidir con la posicion de la etiqueta en this.$refs.chart.chart.data.labels
        data.forEach((item: any) => {
          const label = moment(item.pay_day).format("ddd D");
          const labelPosInLabels =
            this.$refs.chart.chart.data.labels.indexOf(label);

          values[labelPosInLabels] = item.sum;
        });

        // console.log(type, this.$refs.chart.chart.data.labels, values);

        this.$refs.chart.chart.data.datasets.push({
          label: type,
          data: values,
          borderColor: this.colorGenerator(),
        });
      });

      this.$refs.chart.chart.update();
    },
    colorGenerator(): string {
      return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
    },
    prettyDate(date: string): string {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
  },
});
