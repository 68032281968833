













import Vue from "vue";
import TransactionSummary from "@/components/Finantial/TransactionSummary.vue";
import CashFlowSummary from "@/components/Finantial/CashFlowSummary.vue";

export default Vue.extend({
  components: { TransactionSummary, CashFlowSummary },
  name: "sales",
});
