<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card min-height="540" :loading="loading">
      <v-card-title class="d-flex py-1">
        <div class="text-h6 font-weight-regular text--primary">
          Lead Summary
        </div>

        <v-spacer></v-spacer>
        <template v-if="dateInterval !== 'this-year'">
          <div>
            <v-btn color="error" class="mx-2" @click="clearFilter">Clear</v-btn>
          </div>
        </template>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                :loading="loading"
                depressed
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-tune</v-icon>
                Filter
              </v-btn>
            </template>

            <v-list width="140" nav dense>
              <v-list-item
                v-for="interval in intervals"
                :key="interval.value"
                link
                @click="setIntervar(interval.value)"
              >
                <v-list-item-title>{{ interval.label }}</v-list-item-title>
              </v-list-item>
              <v-divider />

              <v-list-item link @click="dateInterval = 'select'">
                <v-list-item-title>Select</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>
      <v-divider />
      <div class="d-flex pa-1">
        <!--   <div>
        <v-select
          :items="workers"
          outlined
          v-model="employeeUuid"
          clearable
          item-text="fullname"
          item-value="uuid"
          @change="getuserSalesSummary"
          dense
          label="Filter by Employee"
        ></v-select>
      </div>
      <div class="mx-1">
        <v-select
          :items="patients"
          v-model="patientUuid"
          clearable
          item-text="fullname"
          item-value="uuid"
          @change="getuserSalesSummary"
          outlined
          dense
          label="Filter by Patient"
        ></v-select>
      </div>-->
      </div>

      {{/* Interval indicator */}}
      <v-layout class="pa-4" column>
        <div class="mr-2 text-capitalize">
          {{ dateInterval.replace("-", " ") }}
        </div>
        <v-layout column class="text-body-1 text--secondary">
          <div>
            From <span class="text-caption">{{ prettyDate(query.date1) }}</span>
          </div>
          <div>
            To <span class="text-caption">{{ prettyDate(query.date2) }}</span>
          </div>
        </v-layout>
      </v-layout>

      {{/* interval selector */}}
      <v-slide-y-transition>
        <v-layout class="mx-4" v-if="dateInterval == 'select'" column>
          <div class="text-body-1 mb-2">Select a date interval</div>
          <v-row no-gutters>
            <v-col>
              <ma-date-picker v-model="dateFrom" label="From" past />
            </v-col>
            <v-col class="ml-2">
              <ma-date-picker v-model="dateTo" label="To" past />
            </v-col>
          </v-row>
        </v-layout>
      </v-slide-y-transition>

      <v-card-text class="d-flex flex-column">
        <div class="d-flex mx-3">
          <div>
            <v-text-field
              class="search"
              label="Filter"
              v-model="search"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valuesValid"
            small
            :color="showGraphic ? 'warning' : 'success'"
            @click="showGraphic = !showGraphic"
            >{{ showGraphic ? "Hide Graph" : "Show Graph" }}</v-btn
          >
          <v-btn
            class="ml-1"
            :disabled="!valuesValid"
            color="primary"
            small
            @click="setDialogPdf"
            >Generate PDF</v-btn
          >
        </div>
        <div class="d-flex">
          <div :class="showGraphic ? 'col col-sm-6' : 'col col-sm-12'">
            <v-data-table
              :search="search"
              :headers="headers"
              :items="datatable"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.percentconverted`]="{ item }">
                {{ getNumber(item.percentconverted) }}
              </template>
              <template v-slot:[`item.average`]="{ item }">
                {{ dayshours(item.average) }}
              </template>

              <template v-slot:[`item.leadscount`]="{ item }">
                <template v-if="item.leadscount != 0">
                  <span
                    class="cursor-pointer"
                    @click="loadDetails('leads', item)"
                    >{{ item.leadscount }}</span
                  >
                </template>
                <template v-else>
                  {{ item.leadscount }}
                </template>
              </template>
              <template v-slot:[`item.patientcount`]="{ item }">
                <template v-if="item.patientcount != 0">
                  <span
                    class="cursor-pointer"
                    @click="loadDetails('patients', item)"
                    >{{ item.patientcount }}</span
                  >
                </template>
                <template v-else>
                  {{ item.patientcount }}
                </template>
              </template>
            </v-data-table>
          </div>

          <template v-if="showGraphic">
            <div class="col col-sm-6 chart">
              <ChartleadSummary ref="charts" :dataChart="dataGraph" />
            </div>
          </template>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogPdf"
      persistent
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          PDF <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="success"
                large
                icon
                @click="generateReport"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
            </template>
            <span>Download PDF</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="error" large icon @click="cancelGenerate"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </v-card-title>
        <div
          id="pdf"
          :style="{
            width: '800px !important',
            padding: '10px 10px 10px 5px !important',
            'text-align': 'center',
          }"
        >
          <v-divider></v-divider>
          <h3>Lead Summary</h3>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Employee</th>
                  <th class="text-right">Total leads</th>
                  <th class="text-right">Converted to patient</th>
                  <th class="text-right">Percent converted</th>
                  <th class="text-right">Total patients</th>
                  <th class="text-right">First payments</th>
                  <th class="text-right">Avg convertion</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in datatable" :key="item.name">
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-right">{{ item.leadscount }}</td>
                  <td class="text-right">{{ item.patientconvertedcount }}</td>
                  <td class="text-right">
                    {{ getNumber(item.percentconverted) }}
                  </td>
                  <td class="text-right">{{ item.patientcount }}</td>
                  <td class="text-right">{{ item.firspaymentcount }}</td>
                  <td class="text-right">{{ dayshours(item.average) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { getAPI } from "@/api/axios-base";

import { mapActions, mapState } from "vuex";

import ChartleadSummary from "./ChartleadSummary.vue";
import { notifyError } from "../Notification";

export default Vue.extend({
  name: "user-sales-summary",
  components: {
    MaDatePicker,
    ChartleadSummary,
  },
  data() {
    return {
      dialogPdf: false,
      dateFrom: "",
      dateTo: "",
      total: 0,
      search: "",
      loading: false,
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      dateInterval: "this-year",
      showGraphic: false,
      leadsSummary: [],
      patientUuid: null,
      employeeUuid: null,
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers"]),

    dataGraph() {
      if (this.search == "" || this.search == null) {
        return this.leadsSummary;
      }
      const x = this.leadsSummary.filter((s) => s.name != "Total");
      const t = [];
      x.forEach((element) => {
        if (
          element.name.includes(this.search) ||
          element.leadscount.toString().includes(this.search) ||
          element.patientconvertedcount.toString().includes(this.search) ||
          element.percentconverted.toString().includes(this.search) ||
          element.patientcount.toString().includes(this.search) ||
          element.firspaymentcount.toString().includes(this.search) ||
          element.average.toString().includes(this.search)
        ) {
          t.push(element);
        } else if (element.name.toLowerCase().includes(this.search)) {
          t.push(element);
        }
      });

      return t;
    },

    datatable() {
      if (this.leadsSummary.length == 0) {
        return [];
      }
      let salesS = this.leadsSummary;

      salesS = salesS.filter((s) => s.name != "Total");

      return salesS;
    },
    valuesValid() {
      if (this.datatable.length == 0) {
        return false;
      }
      const f = this.datatable.filter((dt) => dt.total != 0);
      if (f.length == 0) {
        return false;
      }
      return true;
    },

    headers() {
      return [
        {
          text: "Employee",
          value: "name",
          align: "start",
          sortable: false,
        },
        {
          text: "Total leads",
          value: "leadscount",
          align: "end",
          sortable: true,
        },
        {
          text: "Converted to Patients",
          value: "patientconvertedcount",
          align: "end",
          sortable: true,
        },

        {
          text: "Percent converted",
          value: "percentconverted",
          align: "end",
          sortable: true,
        },
        {
          text: "Total patients",
          value: "patientcount",
          align: "end",
          sortable: true,
        },
        {
          text: "First payments count",
          value: "firspaymentcount",
          align: "end",
          sortable: true,
        },
        {
          text: "Avg conversion time",
          value: "average",
          align: "end",
          sortable: true,
        },
        {
          text: "",
          value: "actions",

          sortable: false,
        },
      ];
    },

    intervalToday() {
      const from = moment().startOf("day");
      const to = moment();
      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").startOf("day");
      const to = moment().subtract(1, "days").endOf("day");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().startOf("week");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").startOf("week");
      const to = moment().subtract(1, "week").endOf("week");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().startOf("month");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment().subtract(1, "month").startOf("month");
      const to = moment().subtract(1, "month").endOf("month");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().startOf("year");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment().subtract(1, "year").startOf("year");
      const to = moment().subtract(1, "year").endOf("year");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
  },
  watch: {
    dateFrom(val) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getuserSalesSummary();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getuserSalesSummary();
      }
    },

    search: {
      handler() {
        this.showGraphic = false;
      },
    },
  },
  async mounted() {
    await this.actGetEmployees();
    await this.actListPatientsFilter();

    await this.getuserSalesSummary();
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),

    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    setIntervar(interval) {
      this.dateInterval = interval;
      this.getuserSalesSummary();
    },

    dayshours(minutos) {
      if (
        minutos == 0 ||
        minutos == null ||
        minutos == undefined ||
        minutos == "0.00"
      ) {
        return 0;
      }

      if (Number(minutos < 60)) {
        if (Number(minutos) < 1) {
          return Math.floor(minutos * 60) + "s";
        }
        return minutos + "m";
      }

      const d = Math.floor(minutos / 1440);
      const h = Math.floor((minutos % 1440) / 60);
      if (h == 0) {
        return `${d}d`;
      }
      return `${d}d, ${h}h`;
    },

    clearFilter() {
      this.setIntervar("this-year");
    },

    getuserSalesSummary() {
      this.showGraphic = false;
      let interval;
      let body = {};
      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "select":
          interval = {
            date1: moment(this.dateFrom).startOf("day").toISOString(),
            date2: moment(this.dateTo).endOf("day").toISOString(),
          };
          break;
      }

      body = { date1: interval.date1, date2: interval.date2 };
      body = this.cleanNull(body);
      this.loading = true;
      this.query = interval;
      getAPI
        .post("/users/leadsSumary1", body)
        .then((res) => {
          this.leadsSummary = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    loadDetails(type, item) {
      this.$router.push("/details_lead_summary/" + type + "_" + item.uuid);
    },

    colorGenerator() {
      return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    getNumber(n) {
      if (n == 0) {
        return n;
      }
      return n + "%";
    },

    getMonth(date) {
      const year = date.split("_")[0];
      const month = date.split("_")[1].split("-")[0];

      const fc = moment(year).add(month - 1, "months");
      return fc.format("MMMM YYYY");
    },

    setDialogPdf() {
      this.dialogPdf = true;
    },
    cancelGenerate() {
      this.dialogPdf = false;
    },

    generateReport() {
      window.html2canvas = html2canvas;
      let doc = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: [1200, 792],
      });
      doc.html(document.querySelector("#pdf"), {
        callback: function (pdf) {
          pdf.save("Lead-Summary.pdf");
        },
      });
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
});
</script>
<style lang="scss" scoped>
.chart {
  padding: 10% 5px !important;
}

.search {
  width: 200px !important;
}
.este {
  border: 1px solid red;
}
</style>
