














import Vue from "vue";
import CartRecentSales from "@/components/Finantial/ChartRecentSales.vue";
import ChartSalesTotal from "@/components/Finantial/ChartSalesTotal.vue";

// import NextApponments from "./CharNextApponments.vue";

export default Vue.extend({
  components: { CartRecentSales, ChartSalesTotal },
  name: "dashboard-finantial",
});
