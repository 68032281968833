<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card min-height="540" :loading="loading">
    <v-card-title class="d-flex py-1">
      <div class="text-h6 font-weight-regular text--primary">Sales Now</div>
      <v-spacer></v-spacer>
      <!-- <v-btn color="success" icon @click="generateReport"
        ><v-icon>mdi-content-save</v-icon></v-btn
      > -->
    </v-card-title>
    <v-divider />
    <div class="d-flex pa-1">
      <div>
        <v-select
          :items="workers"
          outlined
          v-model="employeeUuid"
          clearable
          item-text="fullname"
          item-value="uuid"
          @change="getStrongSummary"
          dense
          label="Filter by Employee"
        ></v-select>
      </div>
      <div class="mx-1">
        <v-select
          :items="patients"
          v-model="patientUuid"
          clearable
          item-text="fullname"
          item-value="uuid"
          @change="getStrongSummary"
          outlined
          dense
          label="Filter by Patient"
        ></v-select>
      </div>

      <template v-if="dateInterval !== 'this-year'">
        <div>
          <v-btn color="error" class="mx-2" @click="clearFilter">Clear</v-btn>
        </div>
      </template>
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              :loading="loading"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-tune</v-icon>
              Filter
            </v-btn>
          </template>

          <v-list width="140" nav dense>
            <v-list-item
              v-for="interval in intervals"
              :key="interval.value"
              link
              @click="setIntervar(interval.value)"
            >
              <v-list-item-title>{{ interval.label }}</v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item link @click="dateInterval = 'select'">
              <v-list-item-title>Select</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    {{/* Interval indicator */}}
    <v-layout class="pa-4" column>
      <div class="mr-2 text-capitalize">
        {{ dateInterval.replace("-", " ") }}
      </div>
      <v-layout column class="text-body-1 text--secondary">
        <div>
          From <span class="text-caption">{{ prettyDate(query.date1) }}</span>
        </div>
        <div>
          To <span class="text-caption">{{ prettyDate(query.date2) }}</span>
        </div>
      </v-layout>
    </v-layout>

    {{/* interval selector */}}
    <v-slide-y-transition>
      <v-layout class="ma-4" v-if="dateInterval == 'select'" column>
        <div class="text-body-1 mb-2">Select a date interval</div>
        <v-row no-gutters>
          <v-col>
            <ma-date-picker v-model="dateFrom" label="From" past />
          </v-col>
          <v-col class="ml-2">
            <ma-date-picker v-model="dateTo" label="To" past />
          </v-col>
        </v-row>
      </v-layout>
    </v-slide-y-transition>

    <v-card-text id="pdf" class="d-flex flex-column">
      <div class="d-flex align-center">
        <div class="text-secondary text-body-1 mb-3">
          Total
          <b class="font-weight-bold text--primary">{{
            totalAmmount | currency
          }}</b>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-select
            :items="types"
            item-text="label"
            item-value="value"
            v-model="grouping"
            @change="getStrongSummary"
            label="Type"
          ></v-select>
        </div>
      </div>
      <chart-base
        ref="chart"
        :width="1300"
        :height="400"
        chart-type="line"
        :chartData="chartData"
        :chartOptions="chartOptions"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import ChartBase from "./ChartBase.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { getAPI } from "@/api/axios-base";

import groupBy from "lodash/groupBy";
import forEach from "lodash/forEach";
import { mapActions, mapState } from "vuex";
// import { isDevelop } from "@/enviorment";

export default Vue.extend({
  name: "sales-now",
  components: {
    ChartBase,
    MaDatePicker,
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",

      loading: false,
      type: "Weeks",
      total: 0,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            borderColor: ["#2196f3"],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          // title: {
          //   display: true,
          //   text: "Summary",
          // },
        },
      },
      types: [
        { label: "Days", value: "DAY_OF_WEEK" },
        { label: "Weeks", value: "WEEK" },
        { label: "Months", value: "MONTH" },
        { label: "Years", value: "YEAR" },
      ],
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      dateInterval: "this-year",
      grouping: "DAY_OF_WEEK",
      strongSummary: [],
      patientUuid: null,
      employeeUuid: null,
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    intervalToday() {
      const from = moment().startOf("day");
      const to = moment();
      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").startOf("day");
      const to = moment().subtract(1, "days").endOf("day");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().startOf("week");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").startOf("week");
      const to = moment().subtract(1, "week").endOf("week");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().startOf("month");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment().subtract(1, "month").startOf("month");
      const to = moment().subtract(1, "month").endOf("month");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().startOf("year");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment().subtract(1, "year").startOf("year");
      const to = moment().subtract(1, "year").endOf("year");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },

    totalAmmount() {
      let count = 0;
      this.strongSummary.forEach((dgg) => {
        if (dgg.grouped == "TOTAL") {
          count = count + Number(dgg.amount);
        }
      });

      return count;
    },
  },
  watch: {
    dateFrom(val) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getStrongSummary();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getStrongSummary();
      }
    },
  },
  async mounted() {
    await this.actGetEmployees();
    await this.actListPatientsFilter();

    await this.getStrongSummary();
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),

    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    setIntervar(interval) {
      this.dateInterval = interval;
      this.getStrongSummary();
    },
    // Agrupar los datos por día de la semana
    datasetsByDay(data) {
      const g = groupBy(data, (item) => {
        let fch = "";
        let label = "";
        switch (this.typegroup) {
          case "DAY_OF_WEEK":
            fch = item.day_of_week.split("_")[0];
            label = moment(fch).format("ddd D");
            break;
          case "WEEK":
            fch = item.week.split("_")[1];
            label = fch;
            break;
          case "MONTH":
            fch = item.month.split("_")[1];
            label = fch;
            break;
          case "YEAR":
            fch = item.year;
            label = fch;
            break;

          default:
            break;
        }
        return label;
      });
      return g;
    },
    clearFilter() {
      this.setIntervar("this-year");
    },
    getData() {
      let interval;

      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "select":
          interval = {
            date1: moment(this.dateFrom).toISOString(),
            date2: moment(this.dateTo).toISOString(),
          };
          break;
      }

      this.loading = true;
      this.query = interval;
      getAPI.post("/payment/filterDays", interval).then((response) => {
        const responseData = response.data;

        this.loading = false;
        this.total = parseFloat(responseData.slice(-1)[0].sum);
      });
    },

    getStrongSummary() {
      let interval;
      let body = {
        employeeUuid: this.employeeUuid,
        patientUuid: this.patientUuid,
      };
      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "select":
          interval = {
            date1: moment(this.dateFrom).toISOString(),
            date2: moment(this.dateTo).toISOString(),
          };
          break;
      }

      body = { ...body, dateRange: interval, grouping: this.grouping };
      body = this.cleanNull(body);
      this.loading = true;
      this.query = interval;
      getAPI.post("/sales/strongSummary", body).then((res) => {
        this.strongSummary = res.data;
        this.createChart(res.data);
        this.loading = false;
      });
    },

    groupingFilter(arraydata) {
      let temp;

      const array = arraydata
        ? arraydata
        : this.strongSummary.filter((sts) => sts.grouped != "TOTAL");

      switch (this.grouping) {
        case "DAY_OF_WEEK":
          temp = groupBy(array, (item) => item.day_of_week);
          break;
        case "WEEK":
          temp = groupBy(array, (item) => item.week);
          break;
        case "MONTH":
          temp = groupBy(array, (item) => item.month);
          break;

        default:
          temp = groupBy(array, (item) => item.year);
          break;
      }
      return temp;
    },

    createChart(data) {
      let mapdays;

      //const datasetsByDay = this.datasetsByDay(dataValues);
      let datasetByType = this.groupingFilter();

      // const datasetByType = groupBy(dataValues, (item) => item.payment_type);
      if (this.grouping == "DAY_OF_WEEK") {
        mapdays = this.createMapDays(this.query.date1, this.query.date2);
        const da = this.createDataarray(mapdays, datasetByType);
        datasetByType = this.groupingFilter(da);
      }
      if (this.grouping == "MONTH") {
        mapdays = this.createMapMonths(this.query.date1, this.query.date2);

        const da = this.createDataarray(mapdays, datasetByType);
        datasetByType = this.groupingFilter(da);
      }
      if (this.grouping == "WEEK") {
        mapdays = this.createMapWeeks(this.query.date1, this.query.date2);

        const da = this.createDataarray(mapdays, datasetByType);
        datasetByType = this.groupingFilter(da);
      }
      if (this.grouping == "YEAR") {
        mapdays = this.createMapYears(this.query.date1, this.query.date2);

        const da = this.createDataarray(mapdays, datasetByType);

        datasetByType = this.groupingFilter(da);
      }

      this.$refs.chart.chart.data.labels = [];
      this.$refs.chart.chart.data.datasets = [];
      this.$refs.chart.chart.update();

      const dat = [
        {
          label: "Amount",
          data: [],
          backgroundColor: ["#2196f3"],
        },
      ];

      // Take labels

      let keys = Object.keys(datasetByType).map((k) => {
        switch (this.grouping) {
          case "DAY_OF_WEEK":
            return moment(k.split("_")[0].split("T")[0]).format("MM-DD-YYYY");

          case "WEEK":
            return (
              k.split("_")[0] +
              "-Week#" +
              this.getNumber(k.split("_")[1].split("-")[0])
            );
          case "MONTH":
            return this.getMonth(k);

          default:
            return k;
        }
      });

      this.$refs.chart.chart.data.labels = keys;

      for (let [type, value] of Object.entries(datasetByType)) {
        dat[0].data.push(value[0].amount);
      }

      this.$refs.chart.chart.data.datasets = dat;

      this.$refs.chart.chart.update();
    },

    createDataarray(array, data) {
      const array_data = [];

      if (this.grouping == "DAY_OF_WEEK") {
        array.forEach((a, i) => {
          array_data.push({
            amount: "0",
            day_of_week: a.split("T")[0] + "_X" + i,
            grouped: "DAY_OF_WEEK",
            quanty: "0",
          });
        });

        array_data.map((ad, i) => {
          Object.entries(data).forEach((k) => {
            if (
              ad.day_of_week.split("_")[0] == k[0].split("_")[0].split("T")[0]
            ) {
              array_data[i] = k[1][0];
            }
          });
        });
      } else if (this.grouping == "MONTH") {
        array.forEach((a, i) => {
          const ty = a.split("T")[0].split("-")[0];
          const tm = a.split("T")[0].split("-")[1];

          array_data.push({
            amount: "0",
            month: ty + "_" + tm + "-X" + i,
            grouped: "MONTH",
            quanty: "0",
          });
        });

        array_data.map((ad, i) => {
          Object.entries(data).forEach((k) => {
            if (ad.month.split("-")[0] == k[0]) {
              array_data[i] = k[1][0];
            }
          });
        });
      } else if (this.grouping == "WEEK") {
        array.forEach((a, i) => {
          array_data.push({
            amount: "0",
            week: a + "-X" + i,
            grouped: "WEEK",
            quanty: "0",
          });
        });

        array_data.map((ad, i) => {
          Object.entries(data).forEach((k) => {
            if (
              ad.week.split("-")[0].split("_")[0] == k[0].split("_")[0] &&
              Number(ad.week.split("-")[0].split("_")[1]) ==
                Number(k[0].split("_")[1])
            ) {
              array_data[i] = k[1][0];
            }
          });
        });
      } else if (this.grouping == "YEAR") {
        array.forEach((a, i) => {
          array_data.push({
            amount: "0",
            year: a,
            grouped: "YEAR",
            quanty: "0",
          });
        });

        array_data.map((ad, i) => {
          Object.entries(data).forEach((k) => {
            if (ad.year == k[0]) {
              array_data[i] = k[1][0];
            }
          });
        });
      }

      return array_data;
    },

    createMapDays(date1, date2) {
      const day1 = moment(date1);
      const dayfinal = moment(date2);
      const arraydays = [];

      arraydays.push(day1.toISOString());

      const n = dayfinal.diff(day1, "days");
      let c = 1;
      for (let index = 1; index < n; index++) {
        const d = moment(date1).add(index, "days");
        arraydays.push(d.toISOString());
      }
      arraydays.push(dayfinal.toISOString());
      return arraydays;
    },
    createMapMonths(date1, date2) {
      const day1 = moment(date1);
      const dayfinal = moment(date2);
      const arraydays = [];

      arraydays.push(day1.toISOString());

      const n = dayfinal.diff(day1, "months");
      let c = 1;
      for (let index = 1; index < n; index++) {
        const d = moment(date1).add(index, "months");
        arraydays.push(d.toISOString());
      }
      arraydays.push(dayfinal.toISOString());
      return arraydays;
    },
    createMapWeeks(date1, date2) {
      const day1 = moment(date1);
      const dayfinal = moment(date2);
      const arraydays = [];

      arraydays.push(day1.year() + "_" + day1.week());

      const n = dayfinal.diff(day1, "weeks");
      let c = 1;
      for (let index = 1; index < n; index++) {
        const d = moment(date1).add(index, "weeks");
        arraydays.push(d.year() + "_" + d.week());
      }
      arraydays.push(dayfinal.year() + "_" + dayfinal.week());

      return arraydays;
    },
    createMapYears(date1, date2) {
      const day1 = moment(date1);
      const dayfinal = moment(date2);
      const arraydays = [];

      arraydays.push(day1.year());

      const n = dayfinal.diff(day1, "years");

      for (let index = 1; index < n; index++) {
        const d = moment(date1).add(index, "years");
        arraydays.push(d.year());
      }
      arraydays.push(dayfinal.year());

      return arraydays;
    },
    colorGenerator() {
      return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    getNumber(n) {
      let rest;
      if (Number(n) < 10) {
        rest = "0" + Number(n);
      } else {
        rest = Number(n);
      }
      return rest;
    },

    getMonth(date) {
      const year = date.split("_")[0];
      const month = date.split("_")[1].split("-")[0];

      const fc = moment(year).add(month - 1, "months");
      return fc.format("MMMM YYYY");
    },

    generateReport() {
      window.html2canvas = html2canvas;
      let doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#pdf"), {
        callback: function (pdf) {
          pdf.save("Sales-Now.pdf");
        },
      });
      this.cancel();
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
});
</script>
